import { isRouteErrorResponse, useRouteError } from '@remix-run/react';
import { useIntl } from 'react-intl';

import { BaseError } from '../base-error';

export function RootErrorBoundary() {
  const error = useRouteError();
  const { formatMessage } = useIntl();

  if (isRouteErrorResponse(error)) {
    return <BaseError title={error.status.toString()} message={error.statusText} stack={error.data} />;
  }

  if (error instanceof Error) {
    return <BaseError title={error.message} stack={error?.stack} />;
  }

  return <BaseError title={formatMessage({ id: 'errors.DEFAULT' })} />;
}
