import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';

const IS_DEV = import.meta.env.MODE === 'development';

export function useMixpanel() {
  const { user } = useAuth0();

  mixpanel.init(MIXPANEL_TOKEN, { debug: IS_DEV, ignore_dnt: IS_DEV, track_pageview: true, autotrack: true });
  mixpanel.identify(user?.sub);
}
