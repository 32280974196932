import { Outlet } from '@remix-run/react';
import { DialogViewport } from '~/components/dialogs/dialog-viewport';
import { ToastViewport } from '~/components/granular/toast';

export function Root() {
  return (
    <div className="box-border flex min-h-screen">
      <Outlet />
      <ToastViewport />
      <DialogViewport />
    </div>
  );
}
